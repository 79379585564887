import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    cart: [],
    banner: {
      active: false
    },
    hours: null
  }),
  actions: {
    restoreCart (cart) {
      const context = this
      cart.forEach((item) => {
        context.cart.push({
          id: item.data.attributes.id,
          image: {
            data: {
              attributes: item.data.attributes.image
            }
          },
          description: item.data.attributes.description,
          price: item.data.attributes.price,
          stripe_product_id: item.data.attributes.stripe_product_id,
          product_name: item.data.attributes.product_name,
          quantity: item.data.attributes.quantity
        })
      })
    },
    addToCart(product) {
      // Check to see if the product is already in the cart
      let productInCart = this.cart.find(item => item.id === product.id)
      if (productInCart) return false
      this.cart.push(product)
      return true
    },
    incrementProductQty(product) {
      // Find the item in the cart
      let indexOfItemInCart = this.cart.findIndex(item => item.id === product.id)
      let quantity = product.quantity + 1
      this.cart[indexOfItemInCart] = {
        ...product,
        quantity
      }
    },
    decrementProductQty(product) {
      if (product.quantity === 1) {
        this.removeProduct(product)
        return
      }
      // Find the item in the cart
      let indexOfItemInCart = this.cart.findIndex(item => item.id === product.id)
      let quantity = product.quantity - 1
      this.cart[indexOfItemInCart] = {
        ...product,
        quantity
      }
    },
    removeProduct (product) {
      // Find the item in the cart
      let indexOfItemInCart = this.cart.findIndex(item => item.id === product.id)
      this.cart.splice(indexOfItemInCart, 1)
    },
    setBanner (banner) {
      this.banner = banner
    },
    setHours (payload) {
      this.hours = payload
    }
  },
  getters: {
    businessHours: (state) => state.hours,
    bannerActive: (state) => state.banner,
    cartItems: (state) => state.cart,
    itemsInCart: (state) => state.cart.length > 0,
    totalItemsInCart: (state) => state.cart.length,
    cartTotal: (state) => {
      let total = 0
      state.cart.forEach((item) => {
        total = total + (item.price * item.quantity)
      })
      return total
    }
  }
})

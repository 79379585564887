<template>
  <nuxt-link
    style="cursor: pointer;"
    :target="target"
    @click="to && router.push({ path: to, hash: hash })">
    <slot></slot>
  </nuxt-link>
</template>

<script setup>
// We can not use <NuxtLink> with headlessui:
// - https://headlessui.com/react/menu#integrating-with-next-js

defineProps(['to', 'target', 'hash']);

const router = useRouter();
</script>

<template>
  <Popover class="relative bg-white z-20">
    <p class="flex h-20 items-center text-center justify-center bg-blue-600 text-sm font-medium text-white px-6">
      {{ store.businessHours.open_days }}<br>
      {{ store.businessHours.closed_days }}
    </p>
    <banner-notice></banner-notice>
    <div class="flex items-center justify-between p-6 md:justify-start md:space-x-10">
      <div>
        <PopoverButton
          to="/"
          :as="myLink"
          class="flex">
          <span class="sr-only">PGP Motorsport Park</span>
          <nuxt-img
            class="h-6 w-auto sm:h-6"
            src="pgplogo_u258no.png"
            provider="cloudinary"
            sizes="xs:220px"
            alt="PGP Motorsport Park Logo"
            densities="x1 x2"></nuxt-img>
        </PopoverButton>
      </div>

      <div class="-my-2 -mr-2 md:hidden">
        <div class="flex">
          <nuxt-link
            v-if="store.itemsInCart"
            to="/cart"
            class="group -m-2 flex items-center p-2">
            <ShoppingBagIcon
              class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true" />
            <span class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{{ store.totalItemsInCart }}</span>
            <span class="sr-only">items in cart, view bag</span>
          </nuxt-link>
          <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open menu</span>
            <Bars3Icon
              class="h-6 w-6"
              aria-hidden="true" />
          </PopoverButton>
        </div>
      </div>
      <div class="hidden md:flex md:flex-1 md:items-center md:justify-between">
        <PopoverGroup
          as="nav"
          class="flex space-x-10">
          <Popover
            v-slot="{ open }"
            class="relative">
            <PopoverButton
              :as="myLink"
              to="/racing"
              :class="[open ? 'text-gray-900' : 'text-slate-700', 'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
              <span>Racing</span>
              <ChevronDownIcon
                :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-slate-700']"
                aria-hidden="true" />
            </PopoverButton>

            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute z-20 -ml-4 mt-3 w-screen max-w-md transform lg:max-w-3xl">
                <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                    <PopoverButton
                      v-for="item in racingOptions"
                      :key="item.name"
                      :as="myLink"
                      :to="`/${item.href}`"
                      :hash="item.hash"
                      class="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50">
                      <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-blue-700 text-white sm:h-12 sm:w-12">
                        <component
                          :is="item.icon"
                          class="h-6 w-6"
                          aria-hidden="true" />
                      </div>
                      <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">
                          {{ item.name }}
                        </p>
                        <p class="mt-1 text-sm text-slate-700">
                          {{ item.description }}
                        </p>
                      </div>
                    </PopoverButton> 
                  </div>
                  <div class="bg-gray-50 p-5 sm:p-8">
                    <a
                      href="tel:2536397223"
                      class="-m-3 flow-root rounded-md p-3 hover:bg-gray-100">
                      <div class="flex items-center">
                        <div class="text-base font-medium text-gray-900">
                          <component
                            :is="PhoneIcon"
                            class="h-6 w-6 inline-block mr-2 align-top"
                            aria-hidden="true" />
                          <span>(253) 639-RACE (7223)</span>
                        </div>
                      </div>
                      <p class="mt-1 text-sm text-gray-500">Open for Arrive and Drive Karting, Wednesday through Sunday</p>
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
          <PopoverButton
            :as="myLink"
            to="/pricing"
            class="text-base font-medium text-slate-700 hover:text-gray-900">
            Pricing
          </PopoverButton>
          <PopoverButton
            :as="myLink"
            to="/events"
            class="text-base font-medium text-slate-700 hover:text-gray-900">
            Group Events
          </PopoverButton>
          <PopoverButton
            :as="myLink"
            to="/shop"
            class="text-base font-medium text-slate-700 hover:text-gray-900">
            Shop
          </PopoverButton>
          <Popover
            v-slot="{ open }"
            class="relative">
            <PopoverButton :class="[open ? 'text-gray-900' : 'text-slate-700', 'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
              <span>More</span>
              <ChevronDownIcon
                :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-slate-700']"
                aria-hidden="true" />
            </PopoverButton>

            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute left-1/2 z-20 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <PopoverButton
                      v-for="item in resources"
                      :key="item.name"
                      :as="myLink"
                      :to="`/${item.href}`"
                      :hash="item.hash"
                      class="-m-3 block rounded-md p-3 hover:bg-gray-50 text-left">
                      <p class="text-base font-medium text-gray-900">
                        {{ item.name }}
                      </p>
                      <p class="mt-1 text-sm text-slate-700">
                        {{ item.description }}
                      </p>
                    </PopoverButton>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </PopoverGroup>
        <div
          v-if="store.itemsInCart"
          class="ml-4 flow-root lg:ml-6">
          <nuxt-link
            to="/cart"
            class="group -m-2 flex items-center p-2">
            <ShoppingBagIcon
              class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true" />
            <span class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{{ store.totalItemsInCart }}</span>
            <span class="sr-only">items in cart, view bag</span>
          </nuxt-link>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95">
      <PopoverPanel
        focus
        class="absolute z-20 inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
        <div class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="px-5 pt-5 pb-6">
            <div class="flex items-center justify-between">
              <div>
                <nuxt-img
                  class="h-6 w-auto"
                  src="pgplogo_u258no.png"
                  provider="cloudinary"
                  sizes="xs:220px"
                  alt="PGP Motorsport Park Logo"
                  densities="x1 x2"></nuxt-img>
              </div>
              <div class="-mr-2">
                <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon
                    class="h-6 w-6"
                    aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-6">
                <PopoverButton
                  v-for="item in racingOptions"
                  :key="item.name"
                  :to="`/${item.href}`"
                  :as="myLink"
                  class="w-full -m-3 flex items-center rounded-lg p-3 hover:bg-gray-50">
                  <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-blue-700 text-white">
                    <component
                      :is="item.icon"
                      class="h-6 w-6"
                      aria-hidden="true" />
                  </div>
                  <div class="ml-4 text-base font-medium text-gray-900">
                    {{ item.name }}
                  </div>
                </PopoverButton>
              </nav>
            </div>
          </div>
          <div class="py-6 px-5">
            <div class="grid grid-cols-2 gap-4">
              <PopoverButton
                :as="myLink"
                to="/pricing"
                class="text-base font-medium text-gray-900 hover:text-gray-700">
                Pricing
              </PopoverButton>
              <PopoverButton
                :as="myLink"
                to="/events"
                class="text-base font-medium text-gray-900 hover:text-gray-700">
                Group Events
              </PopoverButton>
              <PopoverButton
                :as="myLink"
                to="/shop"
                class="text-base font-medium text-gray-900 hover:text-gray-700">
                Shop
              </PopoverButton>
              <PopoverButton
                v-for="item in resources"
                :key="item.name"
                :to="`/${item.href}`"
                :as="myLink"
                class="text-base font-medium text-gray-900 hover:text-gray-700 text-left">
                {{ item.name }}
              </PopoverButton>
            </div>
          </div>
          <div class="bg-gray-50 p-5 sm:p-8">
            <a
              href="tel:2536397223"
              class="-m-3 flow-root rounded-md p-3 hover:bg-gray-100">
              <div class="flex items-center">
                <div class="text-base font-medium text-gray-900">
                  <component
                    :is="PhoneIcon"
                    class="h-6 w-6 inline-block mr-2 align-top"
                    aria-hidden="true" />
                  <span>(253) 639-RACE (7223)</span>
                </div>
              </div>
              <p class="mt-1 text-sm text-gray-500">Open for Arrive and Drive Karting, Wednesday through Sunday</p>
            </a>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import myLink from '~/components/my-link.vue'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  StarIcon,
  TrophyIcon,
  ClockIcon,
  CalendarIcon,
  PhoneIcon,
  XMarkIcon,
  ShoppingBagIcon
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useMainStore } from '@/store/index'
const store = useMainStore()
const racingOptions = [
  {
    name: 'Arrive & Drive',
    description: 'Ready to get out on track, but not sure where to start? Our arrive and drive karting sessions are the perfect starting point.',
    href: 'racing',
    hash: '#arrive-and-drive',
    icon: StarIcon
  },
  {
    name: 'League Racing',
    description: 'Rental kart league racing is the perfect progression from arrive & drive sessions to organized racing.',
    href: 'racing',
    hash: '#rental-league-racing',
    icon: TrophyIcon
  },
  { 
    name: 'Endurance Racing',
    description: "You may have what it takes to put down a fast lap, but can you do it lap after lap after lap?",
    href: 'racing',
    hash: '#endurance-rental-racing',
    icon: ClockIcon
  },
  {
    name: 'Racing Schedule',
    description: "View the current seasons league racing schedule.",
    href: 'racing',
    hash: '#schedule',
    icon: CalendarIcon
  },
  {
    name: 'Live Timing',
    description: "View the current heats lap times.",
    href: 'live-timing',
    icon: ClockIcon
  }
]
const resources = [
  {
    name: 'Track',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: 'track',
  },
  { name: 'Four Stroke', description: 'Learn how to maximize our platform to get the most out of it.', href: 'four-stroke' },
  { name: 'Race School', description: 'See what meet-ups and other events we might be planning near you.', href: 'school' },
  { name: 'Calendar', description: 'View the next 30 days of events at PGP', href: 'calendar' },
]
</script>
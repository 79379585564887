<template>
  <!--
    Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
    your content from being obscured when the user scrolls to the bottom of the page.
  -->
  <div
    v-if="store.banner.active"  
    class="pointer-events-none fixed inset-x-0 top-[152px] sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8 z-10">
    <div class="pointer-events-auto flex items-center justify-between gap-x-6 bg-red-700 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
      <p class="text-lg leading-6 text-white">
        <strong class="font-semibold">{{ store.banner.title }}</strong><svg
          viewBox="0 0 2 2"
          class="mx-2 inline h-0.5 w-0.5 fill-current"
          aria-hidden="true"><circle
            cx="1"
            cy="1"
            r="1" /></svg>{{ store.banner.description }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { useMainStore } from '@/store/index'
const store = useMainStore()
</script>
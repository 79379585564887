import { default as calendar1FXgq9GgQUMeta } from "/opt/render/project/src/pages/calendar.vue?macro=true";
import { default as cartA7TM6HMDZRMeta } from "/opt/render/project/src/pages/cart.vue?macro=true";
import { default as checkout_45completeDGL6go3eY0Meta } from "/opt/render/project/src/pages/checkout-complete.vue?macro=true";
import { default as eventsxKZe6PA5DSMeta } from "/opt/render/project/src/pages/events.vue?macro=true";
import { default as four_45strokeRm65XpbQngMeta } from "/opt/render/project/src/pages/four-stroke.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as live_45timingPZJfyeu8WsMeta } from "/opt/render/project/src/pages/live-timing.vue?macro=true";
import { default as pricingBqz9RBH4dpMeta } from "/opt/render/project/src/pages/pricing.vue?macro=true";
import { default as racinghtxasDq7VOMeta } from "/opt/render/project/src/pages/racing.vue?macro=true";
import { default as schoolEmjHvZCwSBMeta } from "/opt/render/project/src/pages/school.vue?macro=true";
import { default as _91id_932hHNBZAwG3Meta } from "/opt/render/project/src/pages/shop/[id].vue?macro=true";
import { default as indexnNxKU7YTc2Meta } from "/opt/render/project/src/pages/shop/index.vue?macro=true";
import { default as trackiA85TTCDq4Meta } from "/opt/render/project/src/pages/track.vue?macro=true";
export default [
  {
    name: calendar1FXgq9GgQUMeta?.name ?? "calendar",
    path: calendar1FXgq9GgQUMeta?.path ?? "/calendar",
    meta: calendar1FXgq9GgQUMeta || {},
    alias: calendar1FXgq9GgQUMeta?.alias || [],
    redirect: calendar1FXgq9GgQUMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: cartA7TM6HMDZRMeta?.name ?? "cart",
    path: cartA7TM6HMDZRMeta?.path ?? "/cart",
    meta: cartA7TM6HMDZRMeta || {},
    alias: cartA7TM6HMDZRMeta?.alias || [],
    redirect: cartA7TM6HMDZRMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkout_45completeDGL6go3eY0Meta?.name ?? "checkout-complete",
    path: checkout_45completeDGL6go3eY0Meta?.path ?? "/checkout-complete",
    meta: checkout_45completeDGL6go3eY0Meta || {},
    alias: checkout_45completeDGL6go3eY0Meta?.alias || [],
    redirect: checkout_45completeDGL6go3eY0Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/checkout-complete.vue").then(m => m.default || m)
  },
  {
    name: eventsxKZe6PA5DSMeta?.name ?? "events",
    path: eventsxKZe6PA5DSMeta?.path ?? "/events",
    meta: eventsxKZe6PA5DSMeta || {},
    alias: eventsxKZe6PA5DSMeta?.alias || [],
    redirect: eventsxKZe6PA5DSMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/events.vue").then(m => m.default || m)
  },
  {
    name: four_45strokeRm65XpbQngMeta?.name ?? "four-stroke",
    path: four_45strokeRm65XpbQngMeta?.path ?? "/four-stroke",
    meta: four_45strokeRm65XpbQngMeta || {},
    alias: four_45strokeRm65XpbQngMeta?.alias || [],
    redirect: four_45strokeRm65XpbQngMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/four-stroke.vue").then(m => m.default || m)
  },
  {
    name: indexYy8SidDWaAMeta?.name ?? "index",
    path: indexYy8SidDWaAMeta?.path ?? "/",
    meta: indexYy8SidDWaAMeta || {},
    alias: indexYy8SidDWaAMeta?.alias || [],
    redirect: indexYy8SidDWaAMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: live_45timingPZJfyeu8WsMeta?.name ?? "live-timing",
    path: live_45timingPZJfyeu8WsMeta?.path ?? "/live-timing",
    meta: live_45timingPZJfyeu8WsMeta || {},
    alias: live_45timingPZJfyeu8WsMeta?.alias || [],
    redirect: live_45timingPZJfyeu8WsMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/live-timing.vue").then(m => m.default || m)
  },
  {
    name: pricingBqz9RBH4dpMeta?.name ?? "pricing",
    path: pricingBqz9RBH4dpMeta?.path ?? "/pricing",
    meta: pricingBqz9RBH4dpMeta || {},
    alias: pricingBqz9RBH4dpMeta?.alias || [],
    redirect: pricingBqz9RBH4dpMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: racinghtxasDq7VOMeta?.name ?? "racing",
    path: racinghtxasDq7VOMeta?.path ?? "/racing",
    meta: racinghtxasDq7VOMeta || {},
    alias: racinghtxasDq7VOMeta?.alias || [],
    redirect: racinghtxasDq7VOMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/racing.vue").then(m => m.default || m)
  },
  {
    name: schoolEmjHvZCwSBMeta?.name ?? "school",
    path: schoolEmjHvZCwSBMeta?.path ?? "/school",
    meta: schoolEmjHvZCwSBMeta || {},
    alias: schoolEmjHvZCwSBMeta?.alias || [],
    redirect: schoolEmjHvZCwSBMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/school.vue").then(m => m.default || m)
  },
  {
    name: _91id_932hHNBZAwG3Meta?.name ?? "shop-id",
    path: _91id_932hHNBZAwG3Meta?.path ?? "/shop/:id()",
    meta: _91id_932hHNBZAwG3Meta || {},
    alias: _91id_932hHNBZAwG3Meta?.alias || [],
    redirect: _91id_932hHNBZAwG3Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/shop/[id].vue").then(m => m.default || m)
  },
  {
    name: indexnNxKU7YTc2Meta?.name ?? "shop",
    path: indexnNxKU7YTc2Meta?.path ?? "/shop",
    meta: indexnNxKU7YTc2Meta || {},
    alias: indexnNxKU7YTc2Meta?.alias || [],
    redirect: indexnNxKU7YTc2Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: trackiA85TTCDq4Meta?.name ?? "track",
    path: trackiA85TTCDq4Meta?.path ?? "/track",
    meta: trackiA85TTCDq4Meta || {},
    alias: trackiA85TTCDq4Meta?.alias || [],
    redirect: trackiA85TTCDq4Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/track.vue").then(m => m.default || m)
  }
]
<template>
  <div class="mx-auto max-w-7xl">
    <main-navigation></main-navigation>
    <NuxtLayout>
      <NuxtPage></NuxtPage>
    </NuxtLayout>
    <social-footer></social-footer>
  </div>
</template>

<script setup>
import { useMainStore } from '@/store/index'
const config = useRuntimeConfig()
const store = useMainStore()
let banner = Object.assign({})
// Check to see if we need to display the banner
const { data, error } = await useAsyncData('banner', () => $fetch(`${config.public.apiBase}/api/banner-notice?populate=*`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${config.public.apiReadOnlyToken}`
  }
}))

const hoursData = await useAsyncData('hours', () => $fetch(`${config.public.apiBase}/api/business-hour?populate=*`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${config.public.apiReadOnlyToken}`
  }
})).catch((hoursError) => {
  useBugsnag().notify(hoursError)
  return hoursError
})

if (data.value) {
  banner = {
    ...banner,
    ...data.value.data.attributes
  }
  // Save the banner info to the store
  store.setBanner(banner)
} else {
  useBugsnag().notify(error.value)
}

if (hoursData) {
    // Save the hours to the store
    store.setHours(hoursData.data._value.data.attributes)
}

</script>
<template>
  <div class="flex min-h-full flex-col bg-white lg:relative">
    <div class="flex flex-grow flex-col">
      <main class="flex flex-grow flex-col bg-white">
        <div class="mx-auto flex w-full max-w-7xl flex-grow flex-col px-6 lg:px-8">
          <div class="flex-shrink-0 pt-10 sm:pt-16 max-w-xs">
            <a
              href="/"
              class="inline-flex">
              <span class="sr-only">Pacific GP</span>
              <nuxt-img
                src="pgplogo_u258no.png"
                provider="cloudinary"
                sizes="xs:320px"
                densities="x1 x2"></nuxt-img>
            </a>
          </div>
          <div class="my-auto flex-shrink-0 py-16 sm:py-32">
            <p class="text-base font-semibold text-blue-600">
              {{ error.statusCode }}
            </p>
            <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {{ error.statusCode === '404' ? 'Page not found' : 'Server Error' }} 
            </h1>
            <p class="mt-2 text-base text-gray-500">
              {{ error.statusCode === '404' ? 'Sorry, we couldn’t find the page you’re looking for.' : `We're currently unable to load that page. Please try again later.` }} 
            </p>
            <div
              v-if="error.statusCode === '404'"
              class="mt-6">
              <nuxt-link
                to="/"
                class="text-base font-medium text-blue-600 hover:text-blue-500">
                Go back home
                <span aria-hidden="true"> &rarr;</span>
              </nuxt-link>
            </div>
          </div>
        </div>
      </main>
      <footer class="flex-shrink-0 bg-gray-50">
        <div class="mx-auto w-full max-w-7xl py-16 px-6 lg:px-8">
          <nav class="flex space-x-4">
            <div>
              <p class="text-xs leading-5 text-gray-600">
                PGP Motorsport Park <br />
                31003 144th Ave SE, Kent, WA 98042
              </p>
              <a
                class="text-xs leading-5 text-gray-600"
                href="tel:253-639-7223">253-639-RACE(7223)</a>
            </div>
          </nav>
        </div>
      </footer>
    </div>
    <div class="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:block lg:w-1/2">
      <nuxt-img
        src="lost_kart_j2vet3.jpg"
        provider="cloudinary"
        sizes="xs:380px sm:630px md:1250px"
        densities="x1 x2"
        alt="Racing Kart"
        class="absolute inset-0 h-full w-full object-cover"></nuxt-img>
    </div>
  </div>
</template>
<script setup>
import * as NuxtError  from '#app'
const error = defineProps({
  error: {
    ...NuxtError
  }
})
</script>
<style>
#__nuxt {
  height: 100%;
}
</style>